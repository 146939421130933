import { createContext } from '@backyard-ui/utils';
import type { CardProps } from './Card';

export interface CardContext extends Pick<CardProps, 'size'> {}

const { Provider: CardProvider, useContext: useCardContext } =
  createContext<CardContext>({
    name: 'CardContext',
  });

export { CardProvider, useCardContext };
