import React from 'react';
import type { ElementRef } from 'react';

import type { StyleProps } from '@backyard-ui/styles';

import { Group } from '@radix-ui/react-select';
import type { SelectGroupProps as GroupProps } from '@radix-ui/react-select';

export interface SelectGroupProps
  extends StyleProps<Omit<GroupProps, 'asChild'>> {}

const SelectGroup = React.forwardRef<
  ElementRef<typeof Group>,
  SelectGroupProps
>((props, ref) => {
  const { UNSAFE_className, UNSAFE_style, ...rest } = props;

  return (
    <Group
      className={UNSAFE_className}
      style={UNSAFE_style}
      ref={ref}
      {...rest}
    />
  );
});

SelectGroup.displayName = '@backyard-ui/core/SelectGroup';

export default SelectGroup;
