import React from 'react';
import type { ElementRef } from 'react';

import type { UnstyledProps } from '@backyard-ui/styles';

import { Trigger } from '@radix-ui/react-collapsible';
import type { CollapsibleTriggerProps as TriggerProps } from '@radix-ui/react-collapsible';

export interface CollapsibleTriggerProps extends UnstyledProps<TriggerProps> {}

const CollapsibleTrigger = React.forwardRef<
  ElementRef<typeof Trigger>,
  CollapsibleTriggerProps
>((props, ref) => {
  return <Trigger ref={ref} {...props} />;
});

CollapsibleTrigger.displayName = '@backyard-ui/core/CollapsibleTrigger';

export default CollapsibleTrigger;
