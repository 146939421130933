import React from 'react';
import type { ElementRef } from 'react';

import type { StyleProps } from '@backyard-ui/styles';

import { Root, Thumb } from '@radix-ui/react-switch';
import type { SwitchProps as RootProps } from '@radix-ui/react-switch';

import { useFormControl } from '../FormControl/use-form-control';
import type { FormControlOptions } from '../FormControl';

import styles from './Switch.styles';

export interface SwitchProps
  extends StyleProps<Omit<RootProps, 'checked' | 'disabled' | 'required'>>,
    FormControlOptions {
  /**
   * The Switch size
   *
   * @default sm
   */
  size?: 'xs' | 'sm' | 'md';

  /**
   * If `true`, the checkbox will be checked.
   * You'll need to pass onChange to update its value (since it is now controlled)
   */
  isChecked?: boolean;
}

const Switch = React.forwardRef<ElementRef<typeof Root>, SwitchProps>(
  (props, ref) => {
    const {
      size = 'sm',
      isChecked,
      isDisabled,
      isReadOnly,
      UNSAFE_className,
      UNSAFE_style,
      ...rest
    } = props;

    const input = useFormControl({
      isDisabled: isDisabled || isReadOnly,
      isReadOnly,
      ...rest,
    });

    return (
      <Root
        className={styles({ size }).base({
          className: UNSAFE_className,
        })}
        style={UNSAFE_style}
        checked={isChecked}
        ref={ref}
        {...input}
      >
        <Thumb className={styles({ size }).thumb()} />
      </Root>
    );
  }
);

Switch.displayName = '@backyard-ui/core/Switch';

export default Switch;
