import React from 'react';
import type { HTMLAttributes, ElementRef, Ref, ReactNode } from 'react';

import styles from './Placeholder.styles';

export interface PlaceholderProps extends HTMLAttributes<HTMLDivElement> {
  /**
   *
   */
  color?: 'blue' | 'gray' | 'green' | 'purple';

  /**
   * Define the size
   */
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | 'full';

  /**
   *
   */
  padding?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';

  /**
   *
   */
  variant?: 'solid' | 'stripped';

  /**
   * The ref to the HTML DOM element
   */
  ref?: Ref<HTMLDivElement>;

  /**
   * The children elements
   */
  children?: ReactNode;
}

const Placeholder = React.forwardRef<ElementRef<'div'>, PlaceholderProps>(
  (props, ref) => {
    const {
      color = 'gray',
      padding = 'sm',
      size = 'full',
      variant = 'solid',
      className,
      ...rest
    } = props;

    return (
      <div
        className={styles({ color, padding, size, variant, className })}
        ref={ref}
        {...rest}
      />
    );
  }
);

Placeholder.displayName = 'Placeholder';

export default Placeholder;
