import { tv } from 'tailwind-variants';

const styles = tv({
  base: `
    relative
    min-w-0

    break-words
    bg-white
  `,
  slots: {
    header: 'relative',
    body: 'flex-1',
    footer: '',
  },
  variants: {
    size: {
      xs: {
        header: `
          px-3
          pt-3
        `,
        body: `
          px-3
          py-3
        `,
        footer: `
          px-3
          pb-3
        `,
      },
      sm: {
        header: `
          px-4
          pt-4
        `,
        body: `
          px-4
          py-4
        `,
        footer: `
          px-4
          pb-4
        `,
      },
      md: {
        header: `
          px-6
          pt-6
        `,
        body: `
          px-6
          py-6
        `,
        footer: `
          px-6
          pb-6
        `,
      },
      lg: {
        header: `
          px-8
          pt-8
        `,
        body: `
          px-8
          py-8
        `,
        footer: `
          px-8
          pb-8
        `,
      },
      xl: {
        header: `
          px-9
          pt-9
        `,
        body: `
          px-9
          py-9
        `,
        footer: `
          px-9
          pb-9
        `,
      },
    },
    shadow: {
      xs: 'shadow-xs',
      sm: 'shadow-sm',
      md: 'shadow-md',
      lg: 'shadow-lg',
      xl: 'shadow-xl',
      none: 'shadow-none',
    },
    radius: {
      xs: 'rounded-xs',
      sm: 'rounded-sm',
      md: 'rounded',
      lg: 'rounded-lg',
      xl: 'rounded-xl',
      '2xl': 'rounded-2xl',
      none: 'rounded-none',
    },
    hasBorder: {
      true: `
        border
        border-gray-100
      `,
    },
  },
});

export default styles;
