import React from 'react';
import type { HTMLAttributes, ElementRef, Ref, ReactNode } from 'react';

import type { StyleProps } from '@backyard-ui/styles';

import { useCardContext } from './Card.context';

import styles from './Card.styles';

export interface CardFooterProps
  extends StyleProps<HTMLAttributes<HTMLDivElement>> {
  /**
   * The ref to the HTML DOM element
   */
  ref?: Ref<HTMLDivElement>;

  /**
   * The children elements
   */
  children: ReactNode;
}

const Footer = React.forwardRef<ElementRef<'div'>, CardFooterProps>(
  (props, ref) => {
    const { UNSAFE_className, UNSAFE_style, ...rest } = props;
    const { size } = useCardContext();

    return (
      <div
        className={styles({ size }).footer({
          className: UNSAFE_className,
        })}
        style={UNSAFE_style}
        ref={ref}
        {...rest}
      />
    );
  }
);

Footer.displayName = '@backyard-ui/core/CardFooter';

export default Footer;
