import React from 'react';
import type { ElementRef } from 'react';

import type { StyleProps } from '@backyard-ui/styles';

import { Root } from '@radix-ui/react-tabs';
import type { TabsProps as RootProps } from '@radix-ui/react-tabs';

import { TabsProvider } from './Tabs.context';

import styles from './Tabs.styles';

export interface TabsProps extends StyleProps<Omit<RootProps, 'asChild'>> {
  /**
   * The Tab appearance
   *
   * @default primary
   */
  color?: 'primary' | 'secondary' | 'tertiary';
}

const Tabs = React.forwardRef<ElementRef<typeof Root>, TabsProps>(
  (props, ref) => {
    const {
      color = 'primary',
      UNSAFE_className,
      UNSAFE_style,
      ...rest
    } = props;

    return (
      <TabsProvider value={{ color }}>
        <Root
          className={styles().base({
            className: UNSAFE_className,
          })}
          style={UNSAFE_style}
          ref={ref}
          {...rest}
        />
      </TabsProvider>
    );
  }
);

Tabs.displayName = '@backyard-ui/core/Tabs';

export default Tabs;
