import { tv } from 'tailwind-variants';

const styles = tv({
  base: `
    inline-flex

    h-auto
    w-full

    appearance-none

    items-center
    justify-center

    rounded-lg
    border
    border-gray-300

    bg-white
    text-neutral-900

    outline-none

    transition
    duration-100
    ease-in

    placeholder:text-neutral-300
    hover:border-gray-400

    focus:border-primary-600
    focus:ring-2
    focus:ring-primary-300

    disabled:pointer-events-none
    disabled:bg-neutral-100
    disabled:text-neutral-300

    data-[invalid]:border-critical-600
    data-[invalid]:focus:ring-critical-300
  `,
  variants: {
    size: {
      xs: `
        min-h-[theme('spacing.7')]
        px-3
        py-2

        text-sm;
      `,
      sm: `
        min-h-[theme('spacing.10')]
        px-4
        py-3

        text-lg;
      `,
      md: `
        min-h-[theme('spacing.12')]
        px-6
        py-4

        text-xl;
      `,
    },
  },
});

export default styles;
