import React from 'react';
import type { ElementRef, Ref, ReactNode } from 'react';

import type { StyleProps, Token } from '@backyard-ui/styles';

import { Flex } from '../Flex';
import type { FlexProps } from '../Flex';

import { CardProvider } from './Card.context';

import styles from './Card.styles';

export interface CardProps
  extends StyleProps<
    Omit<FlexProps, 'basis' | 'flex' | 'grow' | 'shrink' | 'wrap'>
  > {
  /**
   * The CSS `padding` property
   *
   * @default sm
   */
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';

  /**
   * The CSS `box-shadow` property
   *
   * @default xs
   */
  shadow?: Token<'shadows'>;

  /**
   * The CSS `border-radius` property
   *
   * @default md
   */
  radius?: Token<'radii'>;

  /**
   * The CSS `border` property
   *
   * @default false
   */
  hasBorder?: boolean;

  /**
   * The ref to the HTML DOM element
   */
  ref?: Ref<HTMLDivElement>;

  /**
   * The children elements
   */
  children: ReactNode;
}

const Card = React.forwardRef<ElementRef<'div'>, CardProps>((props, ref) => {
  const {
    size = 'sm',
    radius = 'md',
    shadow = 'xs',
    direction = 'column',
    hasBorder = false,
    UNSAFE_className,
    ...rest
  } = props;

  return (
    <CardProvider value={{ size }}>
      <Flex
        direction={direction}
        className={styles({ radius, shadow, size, hasBorder }).base({
          className: UNSAFE_className,
        })}
        ref={ref}
        {...rest}
      />
    </CardProvider>
  );
});

Card.displayName = '@backyard-ui/core/Card';

export default Card;
