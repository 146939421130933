import React from 'react';
import type { ElementRef } from 'react';

import type { StyleProps } from '@backyard-ui/styles';

import { Content } from '@radix-ui/react-tabs';
import type { TabsContentProps as ContentProps } from '@radix-ui/react-tabs';

import styles from './Tabs.styles';

export interface TabsContentProps
  extends StyleProps<Omit<ContentProps, 'asChild'>> {}

const TabsContent = React.forwardRef<
  ElementRef<typeof Content>,
  TabsContentProps
>((props, ref) => {
  const { UNSAFE_className, UNSAFE_style, ...rest } = props;

  return (
    <Content
      className={styles().content({
        className: UNSAFE_className,
      })}
      style={UNSAFE_style}
      ref={ref}
      {...rest}
    />
  );
});

TabsContent.displayName = '@backyard-ui/core/TabsContent';

export default TabsContent;
