import { tv } from 'tailwind-variants';

const styles = tv({
  slots: {
    base: 'relative',
    content: `
      overflow-hidden

      data-[state=open]:animate-collapsible-slide-down
      data-[state=closed]:animate-collapsible-slide-up
      data-[state=open]:motion-reduce:animate-none
      data-[state=closed]:motion-reduce:animate-none
    `,
  },
});

export default styles;
