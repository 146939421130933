export { default as Root } from './Card';
export type { CardProps } from './Card';

export { default as Header } from './Header';
export type { CardHeaderProps } from './Header';

export { default as Body } from './Body';
export type { CardBodyProps } from './Body';

export { default as Footer } from './Footer';
export type { CardFooterProps } from './Footer';
