import { tv } from 'tailwind-variants';

const styles = tv({
  slots: {
    base: `
      flex
      flex-col
      space-y-4
    `,
    item: `
      flex
      select-none
      items-center
      space-x-2
    `,
    input: `
      block

      rounded-full
      border
      border-gray-600

      bg-white
      outline-none

      transition
      duration-150
      ease-in

      hover:border-gray-700

      focus:border-primary-600
      focus:ring-2
      focus:ring-primary-300

      disabled:pointer-events-none
      disabled:bg-neutral-100
      disabled:text-neutral-300

      data-[invalid]:border-critical-600
      data-[invalid]:focus:ring-critical-300

      [readOnly]:bg-neutral-100
    `,
    indicator: `
      flex
      h-full
      w-full

      rounded-full
      bg-primary-600
    `,
  },
  variants: {
    size: {
      xs: {
        input: `
          h-3
          w-3

          p-0.5

          text-sm
        `,
      },
      sm: {
        input: `
          h-5

          w-5
          p-1

          text-lg
        `,
      },
    },
  },
});

export default styles;
