import React from 'react';
import type { ElementRef } from 'react';

import type { StyleProps } from '@backyard-ui/styles';

import { Trigger } from '@radix-ui/react-accordion';
import type { AccordionTriggerProps as TriggerProps } from '@radix-ui/react-accordion';
import { ChevronDown } from '@backyard-ui/icons';

import styles from './Accordion.styles';

export interface AccordionTriggerProps
  extends StyleProps<Omit<TriggerProps, 'asChild'>> {}

const AccordionTrigger = React.forwardRef<
  ElementRef<typeof Trigger>,
  AccordionTriggerProps
>((props, ref) => {
  const { UNSAFE_className, UNSAFE_style, children, ...rest } = props;

  return (
    <Trigger
      className={styles().trigger({
        className: UNSAFE_className,
      })}
      style={UNSAFE_style}
      ref={ref}
      {...rest}
    >
      {children}
      <ChevronDown className={styles().icon()} />
    </Trigger>
  );
});

AccordionTrigger.displayName = '@backyard-ui/core/AccordionTrigger';

export default AccordionTrigger;
