import type { ReactNode, ChangeEvent } from 'react';

import { useControllableState } from '@backyard-ui/hooks';
import { CheckboxGroupProvider } from './Checkbox.context';

import type { CheckboxProps } from './Checkbox';

export interface CheckboxGroupProps extends Pick<CheckboxProps, 'size'> {
  /**
   * The value of selected checkboxes
   */
  value?: Array<string>;

  /**
   * The initial value
   */
  defaultValue?: string[];

  /**
   * The callback fired when any children Checkbox is checked or unchecked
   */
  onChange?(value: string[]): void;

  /**
   * The children nodes
   */
  children: ReactNode;
}

function CheckboxGroup(props: CheckboxGroupProps) {
  const { size, value, defaultValue, onChange, ...rest } = props;

  const [val, setVal] = useControllableState({
    prop: value,
    defaultProp: defaultValue || [],
    onChange,
  });

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const itemValue = event.currentTarget.value;

    setVal(
      val?.includes(itemValue)
        ? val.filter((item) => item !== itemValue)
        : [...val, itemValue]
    );
  };

  return (
    <CheckboxGroupProvider
      value={{ size, value: val, onChange: handleChange }}
      {...rest}
    />
  );
}

CheckboxGroup.displayName = '@backyard-ui/core/CheckboxGroup';

export default CheckboxGroup;
