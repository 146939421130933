import { tv } from 'tailwind-variants';

const styles = tv({
  base: `
    relative
    w-full

    rounded-t-md
    bg-white
`,
  slots: {
    list: `
      flex
      w-full
      justify-start

      border-b-2
      border-neutral-100
    `,
    trigger: `
      -mb-[2px]

      inline-flex
      cursor-pointer
      items-center

      rounded-t-md
      border-b-2
      border-transparent

      bg-transparent
      px-4

      py-2
      text-lg

      text-neutral-700
      shadow-neutral-100

      outline-none
      transition-colors
      duration-150

      ease-in

      hover:bg-neutral-100
      focus:shadow-[0_0_0_3px]

      data-[disabled]:cursor-not-allowed
      data-[disabled]:text-neutral-300
      data-[state=active]:text-neutral-900
      data-[disabled]:hover:bg-transparent

      [&_svg]:mr-2
      [&_svg]:h-3
      [&_svg]:w-3
    `,
    content: `
      p-4

      outline-none
      focus-visible:shadow-[0_0_0_3px]
      focus-visible:shadow-neutral-100
    `,
  },
  variants: {
    color: {
      primary: {
        trigger: `
          data-[state=active]:border-primary-600
          data-[state=active]:shadow-primary-100
        `,
      },
      secondary: {
        trigger: `
          data-[state=active]:border-secondary-600
          data-[state=active]:shadow-secondary-100
        `,
      },
      tertiary: {
        trigger: `
          data-[state=active]:border-tertiary-600
          data-[state=active]:shadow-tertiary-100
        `,
      },
    },
  },
});

export default styles;
