import React from 'react';
import type { HTMLAttributes, ElementRef, Ref, ReactNode } from 'react';

import { filterFalsyChildren } from '@backyard-ui/utils';

import { GridProvider } from './Grid.context';

import styles from './Grid.styles';

export interface GridProps extends HTMLAttributes<HTMLSpanElement> {
  /**
   * The CSS `align-items` property
   */
  align?: 'center' | 'flex-start' | 'flex-end' | 'baseline' | 'stretch';

  /**
   * The amount of columns in each row
   *
   * @default 12
   */
  columns?: number;

  /**
   * The space between each column
   *
   * @default lg
   */
  gutter?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';

  /**
   * The CSS `justify-content` property
   */
  justify?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly';

  /**
   * If true remove the space between columns
   *
   * @default false
   */
  noGutters?: boolean;

  /**
   * If true the all columns will occupy remaining columns
   * by setting 'flex-grow: 1'
   *
   * @default false
   */
  isGrow?: boolean;

  /**
   * The CSS `flex-direction` property
   *
   * @default false
   */
  isReverse?: boolean;

  /**
   * The ref to the HTML DOM element
   */
  ref?: Ref<HTMLDivElement>;

  /**
   * The children elements
   */
  children: ReactNode;
}

const Grid = React.forwardRef<ElementRef<'div'>, GridProps>((props, ref) => {
  const {
    gutter = 'lg',
    columns = 12,
    align,
    justify,
    isGrow = false,
    isReverse = false,
    noGutters = false,
    className,
    children,
    ...rest
  } = props;

  const cols = filterFalsyChildren(children).map((child, index) =>
    React.cloneElement(child, {
      key: index,
      isGrow: child.props.isGrow ?? isGrow,
    })
  );

  return (
    <GridProvider
      value={{
        columns,
        gutter,
        isGrow,
        noGutters,
      }}
    >
      <div
        className={styles({ align, gutter, justify, isReverse }).base({
          className,
        })}
        ref={ref}
        {...rest}
      >
        {cols}
      </div>
    </GridProvider>
  );
});

Grid.displayName = '@backyard-ui/core/Grid';

export default Grid;
