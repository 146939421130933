import React from 'react';
import type { ChangeEvent } from 'react';

import { useId, useEnhancedEffect } from '@backyard-ui/hooks';
import { mergeRefs, omit } from '@backyard-ui/utils';

import { useFormControlProps } from '../FormControl/use-form-control';
import type { FormControlOptions, Callback } from '../FormControl';

export interface UseCheckboxProps extends FormControlOptions {
  /**
   * The Checkbox value
   */
  value?: string | number;

  /**
   * If `true`, the checkbox will be initially checked.
   */
  defaultChecked?: boolean;

  /**
   * If `true`, the checkbox will be checked.
   * You'll need to pass `onChange` to update its value (since it is now controlled)
   */
  isChecked?: boolean;

  /**
   * If `true`, the checkbox will be indeterminate.
   */
  isIndeterminate?: boolean;

  /**
   *  The callback invoked when the checked state of the `Checkbox` changes.
   */
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export function useCheckbox(props: UseCheckboxProps) {
  const {
    value,
    defaultChecked,
    isChecked: checkedProp,
    isIndeterminate,
    onChange,
    ...rest
  } = props;

  const uuid = useId();

  const { id, isDisabled, isReadOnly, isRequired, isInvalid } =
    useFormControlProps({
      id: `checkbox-${uuid}`,
      ...props,
    });
  const inputRef = React.useRef<HTMLInputElement>(null);

  const [checkedState, setCheckedState] = React.useState(!!defaultChecked);

  const isControlled = checkedProp !== undefined;
  const isChecked = isControlled ? checkedProp : checkedState;

  const htmlProps = omit(rest, [
    'isDisabled',
    'isInvalid',
    'isReadOnly',
    'isRequired',
  ]);

  const handleChange = React.useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (isReadOnly || isDisabled) {
        event.preventDefault();
        return;
      }

      if (!isControlled) {
        if (isChecked) {
          setCheckedState(event.target.checked);
        } else {
          setCheckedState(isIndeterminate ? true : event.target.checked);
        }
      }

      onChange?.(event);
    },
    [isReadOnly, isDisabled, isChecked, isControlled, isIndeterminate, onChange]
  );

  useEnhancedEffect(() => {
    if (inputRef.current) {
      inputRef.current.indeterminate = Boolean(isIndeterminate);
    }
  }, [isIndeterminate]);

  /**
   * HTMLFormElement.reset() should reset the checkbox state
   */
  useEnhancedEffect(() => {
    const el = inputRef.current;
    if (!el?.form) return;

    el.form.onreset = () => {
      setCheckedState(!!defaultChecked);
    };
  }, []);

  const getRootProps = React.useCallback<Callback>(
    (props = {}, ref = null) => {
      return {
        'data-disabled': isDisabled || undefined,
        'data-indeterminate': isIndeterminate || undefined,
        'data-invalid': isInvalid || undefined,
        'data-readonly': isReadOnly || undefined,
        ref,
        ...props,
      };
    },
    [isReadOnly, isIndeterminate, isInvalid, isDisabled]
  );

  const getCheckboxProps = React.useCallback<Callback>(
    (props = {}, ref = null) => {
      return {
        ref,
        'aria-hidden': true,
        isChecked,
        isIndeterminate,
        ...props,
      };
    },
    [isChecked, isIndeterminate]
  );

  const getLabelProps = React.useCallback<Callback>(
    (props = {}, ref = null) => ({
      htmlFor: props.htmlFor ?? id,
      ref,
      ...props,
    }),
    [id]
  );

  const getInputProps = React.useCallback<Callback>(
    (props = {}, ref = null) => {
      return {
        id: props.id ?? id,
        value,
        required: isRequired,
        checked: isChecked,
        disabled: isDisabled,
        readOnly: isReadOnly,
        'data-disabled': isDisabled || undefined,
        'data-indeterminate': isIndeterminate || undefined,
        'data-invalid': isInvalid || undefined,
        'data-readonly': isReadOnly || undefined,
        'aria-invalid': isInvalid || undefined,
        'aria-required': isRequired || undefined,
        'aria-readonly': isReadOnly || undefined,
        ref: mergeRefs(inputRef, ref),
        onChange: props.onChange ?? handleChange,
        ...htmlProps,
      };
    },
    [
      id,
      value,
      isRequired,
      isChecked,
      isReadOnly,
      isIndeterminate,
      isInvalid,
      isDisabled,
      handleChange,
      htmlProps,
    ]
  );

  return {
    getRootProps,
    getCheckboxProps,
    getInputProps,
    getLabelProps,
  };
}
