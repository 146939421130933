import React from 'react';
import type { ElementRef, CSSProperties } from 'react';

import type { StyleProps } from '@backyard-ui/styles';

import { Arrow } from '@radix-ui/react-tooltip';
import type { TooltipArrowProps as ArrowProps } from '@radix-ui/react-tooltip';

import styles from './Tooltip.styles';

export interface TooltipArrowProps
  extends StyleProps<Omit<ArrowProps, 'asChild'>> {}

const TooltipArrow = React.forwardRef<
  ElementRef<typeof Arrow>,
  TooltipArrowProps
>((props, ref) => {
  const {
    width = 10,
    height = 10,
    UNSAFE_className,
    UNSAFE_style,
    ...rest
  } = props;

  let style = {
    '--tooltip-arrow-size': `${-width / 2}px`,
  } as CSSProperties;

  return (
    <Arrow
      width={width}
      height={height}
      className={styles().arrow({
        className: UNSAFE_className,
      })}
      style={{ ...style, ...UNSAFE_style }}
      asChild
      ref={ref}
      {...rest}
    >
      <svg />
    </Arrow>
  );
});

TooltipArrow.displayName = '@backyard-ui/core/TooltipArrow';

export default TooltipArrow;
