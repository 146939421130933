import React from 'react';
import type { ElementRef } from 'react';

import type { StyleProps } from '@backyard-ui/styles';

import { Trigger, SelectIcon } from '@radix-ui/react-select';
import type { SelectTriggerProps as TriggerProps } from '@radix-ui/react-select';

import { ChevronDown } from '@backyard-ui/icons';

import { useSelectContext } from './Select.context';
import { useFormControl } from '../FormControl/use-form-control';

import styles from './Select.styles';

export interface SelectTriggerProps
  extends StyleProps<Omit<TriggerProps, 'asChild'>> {}

const SelectTrigger = React.forwardRef<
  ElementRef<typeof Trigger>,
  SelectTriggerProps
>((props, ref) => {
  const { UNSAFE_className, UNSAFE_style, children, ...rest } = props;

  const { size, ...context } = useSelectContext();
  const input = useFormControl(context);

  return (
    <Trigger
      className={styles({ size }).trigger({
        className: UNSAFE_className,
      })}
      style={UNSAFE_style}
      ref={ref}
      {...input}
      {...rest}
    >
      {children}
      <SelectIcon asChild>
        <ChevronDown />
      </SelectIcon>
    </Trigger>
  );
});

SelectTrigger.displayName = '@backyard-ui/core/SelectTrigger';

export default SelectTrigger;
