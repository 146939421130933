import { tv } from 'tailwind-variants';

const styles = tv({
  slots: {
    base: 'relative',
    arrow: `
      rotate-45
      bg-gray-800

      after:block
      after:h-full
      after:w-full
      after:rounded-sm
      after:bg-gray-800
      after:content-['']

      group-data-[side]:mt-[var(--tooltip-arrow-size)]
      group-data-[side]:rounded-br
    `,
    content: `
      group

      relative
      z-50

      flex
      max-w-[theme('spacing.64')]

      origin-[var(--radix-tooltip-content-transform-origin)]

      items-center
      justify-center

      rounded-md
      bg-gray-800

      p-2

      text-md
      font-semibold
      text-white

      will-change-transform
      animate-in
      duration-150

      data-[side]:data-[state=closed]:animate-out
      data-[side]:data-[state=closed]:fade-out-0
      data-[side]:data-[state=closed]:zoom-out-95
      data-[side=bottom]:data-[state=delayed-open]:slide-in-from-top-1
      data-[side=left]:data-[state=delayed-open]:slide-in-from-right-1
      data-[side=right]:data-[state=delayed-open]:slide-in-from-left-1
      data-[side=top]:data-[state=delayed-open]:slide-in-from-bottom-1
      data-[side]:motion-reduce:animate-none
    `,
  },
});

export default styles;
