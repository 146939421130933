import React from 'react';
import type { InputHTMLAttributes, ElementRef, Ref } from 'react';

import type { StyleProps } from '@backyard-ui/styles';

import { useFormControl } from '../FormControl/use-form-control';
import type { FormControlOptions } from '../FormControl';

import styles from './Textarea.styles';

export interface TextareaProps
  extends StyleProps<Omit<InputHTMLAttributes<HTMLTextAreaElement>, 'size'>>,
    FormControlOptions {
  /**
   * The Input size.
   *
   * @default sm
   */

  size?: 'xs' | 'sm' | 'md';

  /**
   * The ref to the HTML DOM element
   */
  ref?: Ref<HTMLTextAreaElement>;
}

const Textarea = React.forwardRef<ElementRef<'textarea'>, TextareaProps>(
  (props, ref) => {
    const { size = 'sm', UNSAFE_className, UNSAFE_style, ...rest } = props;

    const input = useFormControl(rest);

    return (
      <textarea
        className={styles({ size, className: UNSAFE_className })}
        style={UNSAFE_style}
        ref={ref}
        {...input}
      />
    );
  }
);

Textarea.displayName = '@backyard-ui/core/Textarea';

export default Textarea;
