import React from 'react';
import type { ElementRef } from 'react';

import type { StyleProps } from '@backyard-ui/styles';

import { Item } from '@radix-ui/react-accordion';
import type { AccordionItemProps as ItemProps } from '@radix-ui/react-accordion';

import styles from './Accordion.styles';

export interface AccordionItemProps
  extends StyleProps<Omit<ItemProps, 'asChild'>> {}

const AccordionItem = React.forwardRef<
  ElementRef<typeof Item>,
  AccordionItemProps
>((props, ref) => {
  const { UNSAFE_className, UNSAFE_style, ...rest } = props;

  return (
    <Item
      className={styles().item({
        className: UNSAFE_className,
      })}
      style={UNSAFE_style}
      ref={ref}
      {...rest}
    />
  );
});

AccordionItem.displayName = '@backyard-ui/core/AccordionItem';

export default AccordionItem;
