import React from 'react';
import type { HTMLAttributes, ElementRef, Ref } from 'react';

import type { StyleProps } from '@backyard-ui/styles';

import styles from './Tag.styles';

export interface TagDismissProps
  extends StyleProps<HTMLAttributes<HTMLButtonElement>> {
  /**
   * The ref to the HTML DOM element
   */
  ref?: Ref<HTMLButtonElement>;
}

const TagCloseButton = React.forwardRef<ElementRef<'button'>, TagDismissProps>(
  (props, ref) => {
    const { UNSAFE_className, UNSAFE_style, ...rest } = props;

    return (
      <button
        type="button"
        aria-label="dismis"
        className={styles().dismiss({
          className: UNSAFE_className,
        })}
        style={UNSAFE_style}
        ref={ref}
        {...rest}
      >
        <svg viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
          <path
            fillRule="nonzero"
            d="M8.632 10.01L0 1.376 1.359.017 9.98 8.641 18.619 0l1.316 1.316-8.637 8.638L20 18.658 18.658 20l-8.693-8.696-8.648 8.65L.004 18.64l8.628-8.63"
          />
        </svg>
      </button>
    );
  }
);

TagCloseButton.displayName = '@backyard-ui/core/TagCloseButton';

export default TagCloseButton;
