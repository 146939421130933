import { tv } from 'tailwind-variants';

const styles = tv({
  base: `
    flex
    h-16
    w-full

    items-center
    justify-center

    rounded
    font-semibold
  `,
  variants: {
    color: {
      blue: '',
      gray: '',
      green: '',
      purple: '',
    },
    padding: {
      xs: 'p-4',
      sm: 'p-10',
      md: 'p-16',
      lg: 'p-24',
      xl: 'p-32',
    },
    size: {
      xs: 'w-1/12',
      sm: 'w-2/12',
      md: 'w-4/12',
      lg: 'w-6/12',
      xl: 'w-8/12',
      '2xl': 'w-10/12',
      '3xl': 'w-11/12',
      full: 'w-full',
    },
    variant: {
      solid: '',
      stripped: '',
    },
  },
  compoundVariants: [
    {
      color: 'blue',
      variant: 'solid',
      className: `
        bg-blue-100
        text-blue-600
      `,
    },
    {
      color: 'gray',
      variant: 'solid',
      className: `
        bg-gray-100
        text-gray-600
      `,
    },
    {
      color: 'blue',
      variant: 'solid',
      className: `
        bg-green-100
        text-green-600;
      `,
    },
    {
      color: 'purple',
      variant: 'solid',
      className: `
        bg-purple-100
        text-purple-600
      `,
    },
    //stripped
    {
      color: 'blue',
      variant: 'stripped',
      className: `
        bg-gray-100
        text-gray-600
      `,
    },
    {
      color: 'blue',
      variant: 'stripped',
      className: 'bg-stripes-gray',
    },
    {
      color: 'blue',
      variant: 'stripped',
      className: 'bg-stripes-green',
    },
    {
      color: 'blue',
      variant: 'stripped',
      className: 'bg-stripes-purple',
    },
  ],
});

export default styles;
