import type { HTMLAttributes, ReactNode } from 'react';

import type { ResponsiveValue } from '@backyard-ui/styles';

import { filterFalsyValue } from '@backyard-ui/utils';
import { getColumnSize, getColumnOffset } from './Grid.utils';

import { useGridContext } from './Grid.context';

import styles from './Grid.styles';

export interface GridItemProps extends HTMLAttributes<HTMLDivElement> {
  /**
   * The number of columns the component will take place.
   * The value might be a number for all breakpoints or
   * an object that will apply each value
   * based on the key breakpoint, eg:
   * { md: 8 } will create a style for the md media query with a
   * size of 8 columns.
   */
  size?: ResponsiveValue<number | 'auto'>;

  /**
   * The number of columns the component will skip.
   * The value might be a number for all breakpoints or
   * an object that will apply each value
   * based on the key breakpoint, eg:
   * { md: 8 } will create a style for the md media query with an
   * offset of 8 columns. Accepts negative values as well.
   */
  offset?: ResponsiveValue<number> | number;

  /**
   * If true the component will occupy remaining columns
   * by setting 'flex-grow: 1'
   */
  isGrow?: boolean;

  /**
   * The child nodes
   */
  children: ReactNode;
}

const GridItem = (props: GridItemProps) => {
  const { size, offset, isGrow, className, style, ...rest } = props;
  const { columns, gutter, isGrow: grow, noGutters } = useGridContext();

  const dynamicStyles = filterFalsyValue({
    ...getColumnSize(size, columns),
    ...getColumnOffset(offset, columns),
    '--grid-item-flex': size ? '0 0 auto' : '1 0 0%',
  });

  return (
    <div
      className={styles({ gutter, isGrow: grow ?? isGrow, noGutters }).item({
        className,
      })}
      style={{
        ...dynamicStyles,
        ...style,
      }}
      {...rest}
    />
  );
};

GridItem.displayName = '@backyard-ui/core/GridItem';

export default GridItem;
