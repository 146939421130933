import React from 'react';
import type { ElementRef } from 'react';

import type { StyleProps } from '@backyard-ui/styles';

import { Trigger } from '@radix-ui/react-tabs';
import type { TabsTriggerProps as TriggerProps } from '@radix-ui/react-tabs';

import { useTabsContext } from './Tabs.context';

import styles from './Tabs.styles';

export interface TabsTriggerProps
  extends StyleProps<Omit<TriggerProps, 'asChild' | 'disabled'>> {
  /**
   *
   */
  isDisabled?: boolean;
}

const TabsTrigger = React.forwardRef<
  ElementRef<typeof Trigger>,
  TabsTriggerProps
>((props, ref) => {
  const { UNSAFE_className, UNSAFE_style, isDisabled, ...rest } = props;
  const { color } = useTabsContext();

  return (
    <Trigger
      className={styles({ color }).trigger({
        className: UNSAFE_className,
      })}
      style={UNSAFE_style}
      disabled={isDisabled}
      ref={ref}
      {...rest}
    />
  );
});

TabsTrigger.displayName = '@backyard-ui/core/TabsTrigger';

export default TabsTrigger;
