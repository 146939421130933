import { Checkmark } from '@backyard-ui/icons';

import type { UseCheckboxProps } from './use-checkbox';

export interface CheckboxIcon
  extends Pick<UseCheckboxProps, 'isChecked' | 'isIndeterminate'> {}

export function Icon(props: CheckboxIcon) {
  const { isIndeterminate, isChecked, ...rest } = props;

  const icon = isIndeterminate ? (
    <svg fill="currentColor" {...rest}>
      <rect width={12} height={12} rx={3} stroke="#fff" strokeWidth="2" />
    </svg>
  ) : (
    <Checkmark {...rest} />
  );

  return isChecked || isIndeterminate ? icon : null;
}
