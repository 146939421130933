import React from 'react';
import type { ElementRef } from 'react';

import type { StyleProps } from '@backyard-ui/styles';

import { Content } from '@radix-ui/react-collapsible';
import type { CollapsibleContentProps as ContentProps } from '@radix-ui/react-collapsible';

import styles from './Collapsible.styles';

export interface CollapsibleContentProps
  extends StyleProps<Omit<ContentProps, 'asChild'>> {}

const CollapsibleContent = React.forwardRef<
  ElementRef<typeof Content>,
  CollapsibleContentProps
>((props, ref) => {
  const { UNSAFE_className, UNSAFE_style, ...rest } = props;

  return (
    <Content
      className={styles().content({ className: UNSAFE_className })}
      style={UNSAFE_style}
      ref={ref}
      {...rest}
    />
  );
});

CollapsibleContent.displayName = '@backyard-ui/core/CollapsibleContent';

export default CollapsibleContent;
