import { genResponsiveVars } from '@backyard-ui/styles';
import type { ResponsiveValue } from '@backyard-ui/styles';

import { isObject } from '@backyard-ui/utils';

import type { GridProps } from './Grid';
import type { GridItemProps } from './Item';

/**
 *
 * Prevents to return floating values
 */
const isValidValue = (value?: number) =>
  typeof value === 'number' && value > 0 && value % 1 === 0;

export function isValidSize(size?: ResponsiveValue<number> | number) {
  if (isObject(size)) {
    return Object.entries(size).every(([_, value]) => isValidValue(value));
  }

  return isValidValue(size);
}

export function getColumnSize(
  size: GridItemProps['size'],
  columns: GridProps['columns']
) {
  return genResponsiveVars(size, 'grid-item-size', (value) => {
    if (value) {
      // the default value comes from `Grid`
      //@ts-ignore
      return value === 'auto' ? 'auto' : `${(value / columns) * 100}%`;
    }

    return '100%';
  });
}

export function getColumnOffset(
  offset: GridItemProps['offset'],
  columns: GridProps['columns']
) {
  if (!isValidSize(offset)) {
    return null;
  }

  return genResponsiveVars(
    offset,
    'grid-item-offset',
    // the default value comes from `Grid`
    //@ts-ignore
    (value) => `${(value / columns) * 100}%`
  );
}
