import React from 'react';
import type { ElementRef } from 'react';

import type { StyleProps } from '@backyard-ui/styles';

import { Root } from '@radix-ui/react-radio-group';
import type { RadioGroupProps as RootProps } from '@radix-ui/react-radio-group';

import { RadioGroupProvider } from './RadioGroup.context';

import styles from './RadioGroup.styles';

export interface RadioGroupProps
  extends StyleProps<Omit<RootProps, 'asChild'>> {
  /**
   * The RadioItem size
   *
   * @default sm
   */
  size?: 'xs' | 'sm';
}

const RadioGroup = React.forwardRef<ElementRef<typeof Root>, RadioGroupProps>(
  (props, ref) => {
    const { size = 'sm', UNSAFE_className, UNSAFE_style, ...rest } = props;

    return (
      <RadioGroupProvider value={{ size }}>
        <Root
          className={styles().base({
            className: UNSAFE_className,
          })}
          ref={ref}
          {...rest}
        />
      </RadioGroupProvider>
    );
  }
);

RadioGroup.displayName = '@backyard-ui/core/RadioGroup';

export default RadioGroup;
