import React from 'react';
import type { ElementRef, Ref } from 'react';

import type { StyleProps } from '@backyard-ui/styles';

import { Root } from '@radix-ui/react-tooltip';
import type { TooltipProps as RootProps } from '@radix-ui/react-tooltip';

import styles from './Tooltip.styles';

export interface TooltipProps extends StyleProps<Omit<RootProps, 'asChild'>> {
  /**
   * The ref to the HTML DOM element
   */
  ref?: Ref<HTMLDivElement>;
}

const Tooltip = React.forwardRef<ElementRef<typeof Root>, TooltipProps>(
  (props, ref) => {
    const { UNSAFE_className, UNSAFE_style, ...rest } = props;

    return (
      <div
        className={styles().base({
          className: UNSAFE_className,
        })}
        style={UNSAFE_style}
      >
        <Root ref={ref} {...rest} />
      </div>
    );
  }
);

Tooltip.displayName = '@backyard-ui/core/Tooltip';

export default Tooltip;
