import React from 'react';
import type { ElementRef } from 'react';

import type { StyleProps } from '@backyard-ui/styles';

import { Content } from '@radix-ui/react-accordion';
import type { AccordionContentProps as ContentProps } from '@radix-ui/react-accordion';

import styles from './Accordion.styles';

export interface AccordionContentProps
  extends StyleProps<Omit<ContentProps, 'asChild'>> {}

const AccordionContent = React.forwardRef<
  ElementRef<typeof Content>,
  AccordionContentProps
>((props, ref) => {
  const { UNSAFE_className, UNSAFE_style, children, ...rest } = props;

  return (
    <Content
      className={styles().content({
        className: UNSAFE_className,
      })}
      style={UNSAFE_style}
      ref={ref}
      {...rest}
    >
      <div className={styles().text()}>{children}</div>
    </Content>
  );
});

AccordionContent.displayName = '@backyard-ui/core/AccordionContent';

export default AccordionContent;
