import React from 'react';
import type { ElementRef } from 'react';

import type { StyleProps } from '@backyard-ui/styles';

import {
  Content,
  Viewport,
  Portal,
  ScrollUpButton,
  ScrollDownButton,
} from '@radix-ui/react-select';
import type { SelectContentProps as ContentProps } from '@radix-ui/react-select';

import { ChevronUp, ChevronDown } from '@backyard-ui/icons';

import styles from './Select.styles';

export interface SelectContentProps
  extends StyleProps<Omit<ContentProps, 'asChild'>> {}

const SelectContent = React.forwardRef<
  ElementRef<typeof Content>,
  SelectContentProps
>((props, ref) => {
  const {
    sideOffset = 4,
    UNSAFE_className,
    UNSAFE_style,
    children,
    ...rest
  } = props;

  return (
    <Portal>
      <Content
        position="popper"
        sideOffset={sideOffset}
        className={styles().content({
          className: UNSAFE_className,
        })}
        style={UNSAFE_style}
        ref={ref}
        {...rest}
      >
        <ScrollUpButton className={styles().scroll()}>
          <ChevronUp />
        </ScrollUpButton>
        <Viewport className={styles().viewport()}>{children}</Viewport>
        <ScrollDownButton className={styles().scroll()}>
          <ChevronDown />
        </ScrollDownButton>
      </Content>
    </Portal>
  );
});

SelectContent.displayName = '@backyard-ui/core/SelectContent';

export default SelectContent;
