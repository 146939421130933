import React from 'react';
import type { ElementRef } from 'react';

import type { StyleProps } from '@backyard-ui/styles';

import { Content, Portal } from '@radix-ui/react-tooltip';
import type { TooltipContentProps as ContentProps } from '@radix-ui/react-tooltip';
import Arrow from './Arrow';

import styles from './Tooltip.styles';

export interface TooltipContentProps
  extends StyleProps<Omit<ContentProps, 'asChild'>> {}

const TooltipContent = React.forwardRef<
  ElementRef<typeof Content>,
  TooltipContentProps
>((props, ref) => {
  const {
    sideOffset = 4,
    UNSAFE_className,
    UNSAFE_style,
    children,
    ...rest
  } = props;

  return (
    <Portal>
      <Content
        className={styles().content({
          className: UNSAFE_className,
        })}
        style={UNSAFE_style}
        sideOffset={sideOffset}
        ref={ref}
        {...rest}
      >
        <Arrow />
        {children}
      </Content>
    </Portal>
  );
});

TooltipContent.displayName = '@backyard-ui/core/TooltipContent';

export default TooltipContent;
