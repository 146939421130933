import { tv } from 'tailwind-variants';

const styles = tv({
  slots: {
    base: `
      box-border

      flex
      flex-wrap
    `,
    item: `
      relative

      ml-[var(--grid-item-offset-initial)]
      w-full
      w-[var(--grid-item-size-initial)]
      max-w-full

      shrink-0
      flex-[var(--grid-item-flex)]

      sm:ml-[var(--grid-item-offset-sm)]
      sm:w-[var(--grid-item-size-sm)]
      md:ml-[var(--grid-item-offset-md)]
      md:w-[var(--grid-item-size-md)]


      lg:ml-[var(--grid-item-offset-lg)]
      lg:w-[var(--grid-item-size-lg)]

      xl:ml-[var(--grid-item-offset-xl)]
      xl:w-[var(--grid-item-size-xl)]

      2xl:ml-[var(--grid-item-offset-2xl)]
      2xl:w-[var(--grid-item-size-2xl)]
    `,
  },
  variants: {
    align: {
      'flex-start': 'flex-start',
      'flex-end': 'flex-end',
      center: 'center',
      baseline: 'baseline',
      stretch: 'stretch',
    },
    gutter: {
      xs: '',
      sm: '',
      md: '',
      lg: '',
      xl: '',
    },
    justify: {
      'flex-start': 'flex-start',
      'flex-end': 'flex-end',
      center: 'center',
      'space-between': 'space-between',
      'space-around': 'space-around',
      'space-evenly': 'space-evenly',
    },
    isGrow: {
      true: {
        item: `
          max-w-[unset]
          grow
        `,
      },
    },
    isReverse: {
      true: 'flex-row-reverse',
    },
    noGutters: {
      true: {
        base: 'mx-0',
        item: 'px-0',
      },
    },
  },
  compoundVariants: [
    {
      gutter: 'xs',
      className: {
        base: '-mx-0.5',
        item: 'px-0.5',
      },
    },
    {
      gutter: 'sm',
      className: {
        base: '-mx-1',
        item: 'px-1',
      },
    },
    {
      gutter: 'md',
      className: {
        base: '-mx-1.5',
        item: 'px-1.5',
      },
    },
    {
      gutter: 'lg',
      className: {
        base: '-mx-2',
        item: 'px-2',
      },
    },
    {
      gutter: 'xl',
      className: {
        base: '-mx-2.5',
        item: 'px-2.5',
      },
    },
  ],
});

export default styles;
