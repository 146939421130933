import React from 'react';
import type { ElementRef } from 'react';

import type { StyleProps } from '@backyard-ui/styles';

import { Item, Indicator } from '@radix-ui/react-radio-group';
import type { RadioGroupItemProps as ItemPropsBase } from '@radix-ui/react-radio-group';

import { useId } from 'react';

import { useRadioGroupContext } from './RadioGroup.context';
import { useFormControl } from '../FormControl/use-form-control';
import { useFormControlContext } from '../FormControl/FormControl.context';
import type { FormControlOptions } from '../FormControl';

import styles from './RadioGroup.styles';

export interface RadioGroupItemProps
  extends StyleProps<Omit<ItemPropsBase, 'asChild'>>,
    FormControlOptions {}

const RadioGroupItem = React.forwardRef<
  ElementRef<typeof Item>,
  RadioGroupItemProps
>((props, ref) => {
  const {
    value,
    UNSAFE_className,
    UNSAFE_style,
    isDisabled,
    isReadOnly,
    children,
    ...rest
  } = props;

  const uuid = useId();
  const input = useFormControl({
    id: `radio-${uuid}`,
    isDisabled: isDisabled || isReadOnly,
    isReadOnly,
    ...rest,
  });
  const label = useFormControlContext();
  const { size } = useRadioGroupContext();

  return (
    <div
      className={styles().item({
        className: UNSAFE_className,
      })}
      style={UNSAFE_style}
    >
      <Item
        className={styles({ size }).input()}
        value={value}
        ref={ref}
        {...input}
      >
        <Indicator className={styles().indicator()} />
      </Item>
      <label
        {...(label?.getLabelProps({
          id: input.id,
          htmlFor: input.id,
        }) ?? {
          id: input.id,
          htmlFor: input.id,
        })}
      >
        {children}
      </label>
    </div>
  );
});

RadioGroupItem.displayName = '@backyard-ui/core/RadioGroupItem';

export default RadioGroupItem;
