import { tv } from 'tailwind-variants';

const styles = tv({
  base: `
    w-full
    space-y-4

    bg-white
  `,
  slots: {
    item: `
      overflow-hidden
      rounded-lg

      border
      border-transparent
      shadow-xs

      outline-none

      transition
      duration-150
      ease-in

      focus-within:relative
      focus-within:z-10
      focus-within:ring
      focus-within:ring-primary-300

      data-[state=open]:border-gray-200
      data-[state=open]:bg-white
    `,
    header: `
      flex
      w-full

      text-lg
      text-gray-900
    `,
    trigger: `
      group
      flex

      h-12
      w-full

      items-center
      justify-between

      px-6
      py-4

      outline-none

      [&_svg]:h-3
      [&_svg]:w-3

      [&_svg]:text-gray-600

      [&_svg]:transition-transform
      [&_svg]:duration-200
      [&_svg]:ease-in
    `,
    icon: `
      ml-4
      group-data-[state=open]:rotate-180
      group-data-[state=open]:text-gray-900
    `,
    content: `
      w-full

      data-[state=closed]:animate-accordion-slide-up
      data-[state=open]:animate-accordion-slide-down
      data-[state=closed]:motion-reduce:animate-none
      data-[state=open]:motion-reduce:animate-none
    `,
    text: `
      px-6
      pb-4

      text-md
      text-gray-900
    `,
  },
});

export default styles;
