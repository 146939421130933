import React from 'react';
import type { ElementRef } from 'react';

import { Checkmark } from '@backyard-ui/icons';
import type { StyleProps } from '@backyard-ui/styles';

import { Item, ItemText, ItemIndicator } from '@radix-ui/react-select';
import type { SelectItemProps as ItemProps } from '@radix-ui/react-select';

import { useSelectContext } from './Select.context';

import styles from './Select.styles';

export interface SelectItemProps
  extends StyleProps<Omit<ItemProps, 'asChild' | 'disabled'>> {
  /**
   *
   */
  isDisabled?: boolean;
}

const SelectItem = React.forwardRef<ElementRef<typeof Item>, SelectItemProps>(
  (props, ref) => {
    const { UNSAFE_className, UNSAFE_style, children, isDisabled, ...rest } =
      props;
    const { size } = useSelectContext();

    return (
      <Item
        className={styles({ size }).item({
          className: UNSAFE_className,
        })}
        style={UNSAFE_style}
        disabled={isDisabled}
        ref={ref}
        {...rest}
      >
        <ItemText>{children}</ItemText>
        <ItemIndicator asChild>
          <Checkmark />
        </ItemIndicator>
      </Item>
    );
  }
);

SelectItem.displayName = '@backyard-ui/core/SelectItem';

export default SelectItem;
