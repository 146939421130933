import React from 'react';
import type { ElementRef } from 'react';

import type { UnstyledProps } from '@backyard-ui/styles';

import { Trigger } from '@radix-ui/react-tooltip';
import type { TooltipTriggerProps as TriggerProps } from '@radix-ui/react-tooltip';

export interface TooltipTriggerProps extends UnstyledProps<TriggerProps> {}

const TooltipTrigger = React.forwardRef<
  ElementRef<typeof Trigger>,
  TooltipTriggerProps
>((props, ref) => {
  return <Trigger ref={ref} {...props} />;
});

TooltipTrigger.displayName = '@backyard-ui/core/TooltipTrigger';

export default TooltipTrigger;
