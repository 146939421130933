import { tv } from 'tailwind-variants';

const styles = tv({
  base: `
    relative
    w-full
  `,
  slots: {
    trigger: `
      inline-flex

      h-auto
      w-full

      select-none
      appearance-none

      items-center
      justify-between
      space-x-4

      rounded-lg
      border
      border-gray-300

      bg-white
      text-neutral-900

      outline-none

      transition
      duration-100
      ease-in

      hover:border-gray-400

      focus:border-primary-600
      focus:ring-2
      focus:ring-primary-300

      disabled:pointer-events-none
      disabled:bg-neutral-100
      disabled:text-neutral-300

      data-[invalid]:border-critical-600
      data-[placeholder]:text-neutral-300
      data-[invalid]:focus:ring-critical-300

      [&_svg]:h-2.5
      [&_svg]:w-2.5

      [readOnly]:bg-neutral-100
    `,
    content: `
      relative
      z-50

      overflow-hidden

      rounded-md
      bg-white
      shadow-xs

      will-change-transform
      animate-in
      duration-150

      data-[side=bottom]:data-[state=open]:slide-in-from-top-1
      data-[side=top]:data-[state=open]:slide-in-from-bottom-1
      data-[side]:data-[state=open]:motion-reduce:animate-none

      [&_svg]:h-2.5
      [&_svg]:w-2.5
    `,
    viewport: `
      h-[var(--radix-select-trigger-height)]
      w-full
      min-w-[var(--radix-select-trigger-width)]

      p-1
    `,
    item: `
      flex
      cursor-pointer
      items-center
      justify-between

      rounded-md

      px-3
      py-1.5

      text-neutral-900
      outline-none

      transition
      duration-150
      ease-in

      hover:bg-neutral-100
      data-[disabled]:cursor-not-allowed

      data-[highlighted]:bg-neutral-100
      data-[disabled]:text-neutral-300
      data-[disabled]:hover:bg-transparent
    `,
    separator: `
      -mx-1
      my-1

      h-px

      w-[calc(100%+theme('spacing.2'))]
      bg-gray-100
    `,
    label: `
      px-3
      py-2

      font-semibold
      text-neutral-700
    `,
    scroll: `
      -mx-1
      flex

      justify-center
      border-neutral-100

      py-2
    `,
  },
  variants: {
    size: {
      xs: {
        trigger: `
          h-7
          px-3

          text-sm;
        `,

        item: 'text-sm',
        label: 'text-sm',
      },

      sm: {
        trigger: `
          h-10
          px-4

          text-lg;
        `,

        item: 'text-lg',
        label: 'text-md',
      },

      md: {
        trigger: `
          h-12
          px-6

          text-xl;
        `,

        item: 'text-lg',
        label: 'text-lg',
      },
    },
  },
});

export default styles;
