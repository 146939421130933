import React from 'react';
import type { ElementRef } from 'react';

import type { StyleProps } from '@backyard-ui/styles';

import { List } from '@radix-ui/react-tabs';
import type { TabsListProps as ListProps } from '@radix-ui/react-tabs';

import styles from './Tabs.styles';

export interface TabsListProps
  extends StyleProps<Omit<ListProps, 'asChild' | 'loop'>> {
  /**
   *
   */
  isLoop?: boolean;
}

const TabsList = React.forwardRef<ElementRef<typeof List>, TabsListProps>(
  (props, ref) => {
    const { UNSAFE_className, UNSAFE_style, isLoop, ...rest } = props;

    return (
      <List
        className={styles().list({
          className: UNSAFE_className,
        })}
        style={UNSAFE_style}
        loop={isLoop}
        ref={ref}
        {...rest}
      />
    );
  }
);

TabsList.displayName = '@backyard-ui/core/TabsList';

export default TabsList;
