import { tv } from 'tailwind-variants';

const styles = tv({
  base: `
    box-border
    inline-flex
    h-5

    cursor-default
    select-none

    items-center
    justify-center

    rounded-xl
    bg-neutral-200

    px-2
    py-1

    text-left
  `,
  slots: {
    label: `
      line-clamp-1
      text-sm
      font-semibold
      text-gray-800
      break-all
    `,
    dismiss: `
      relative
      left-1
      flex

      shrink-0

      cursor-pointer
      select-none
      appearance-none

      rounded-full
      border-none

      bg-transparent

      p-1

      text-inherit
      outline-none

      transition
      duration-100
      ease-in

      hover:bg-gray-300
      focus:ring-2
      focus:ring-gray-500
      active:bg-gray-300

      [&_svg]:h-2
      [&_svg]:w-2
    `,
  },
});

export default styles;
