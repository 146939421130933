import React from 'react';
import type { ElementRef } from 'react';

import type { StyleProps } from '@backyard-ui/styles';

import { Separator } from '@radix-ui/react-select';
import type { SelectSeparatorProps as SeparatorProps } from '@radix-ui/react-select';

import styles from './Select.styles';

export interface SelectSeparatorProps
  extends StyleProps<Omit<SeparatorProps, 'asChild'>> {}

const SelectSeparator = React.forwardRef<
  ElementRef<typeof Separator>,
  SelectSeparatorProps
>((props, ref) => {
  const { UNSAFE_className, UNSAFE_style, ...rest } = props;

  return (
    <Separator
      className={styles().separator()}
      style={UNSAFE_style}
      ref={ref}
      {...rest}
    />
  );
});

SelectSeparator.displayName = '@backyard-ui/core/SelectSeparator';

export default SelectSeparator;
