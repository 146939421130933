import React from 'react';
import type { ElementRef } from 'react';

import type { StyleProps } from '@backyard-ui/styles';

import { Value } from '@radix-ui/react-select';
import type { SelectValueProps as ValueProps } from '@radix-ui/react-select';

export interface SelectValueProps
  extends StyleProps<Omit<ValueProps, 'asChild'>> {}

const SelectValue = React.forwardRef<
  ElementRef<typeof Value>,
  SelectValueProps
>((props, ref) => {
  const { UNSAFE_className, UNSAFE_style, children, ...rest } = props;

  return (
    <Value
      className={UNSAFE_className}
      style={UNSAFE_style}
      ref={ref}
      {...rest}
    />
  );
});

SelectValue.displayName = '@backyard-ui/core/SelectValue';

export default SelectValue;
