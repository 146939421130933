import { Provider } from '@radix-ui/react-tooltip';
import type { TooltipProviderProps as TooltipProviderPropsBase } from '@radix-ui/react-tooltip';

export interface TooltipProviderProps extends TooltipProviderPropsBase {}

const TooltipProvider = (props: TooltipProviderProps) => {
  let { delayDuration = 100, ...rest } = props;

  return <Provider delayDuration={delayDuration} {...rest} />;
};

TooltipProvider.displayName = '@backyard-ui/core/TooltipProvider';

export default TooltipProvider;
