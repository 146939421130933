import React from 'react';
import type { ElementRef } from 'react';

import type { StyleProps } from '@backyard-ui/styles';

import { Root } from '@radix-ui/react-aspect-ratio';
import type { AspectRatioProps as RootProps } from '@radix-ui/react-aspect-ratio';

export interface AspectRatioProps
  extends StyleProps<Omit<RootProps, 'asChild'>> {}

const AspectRatio = React.forwardRef<ElementRef<typeof Root>, AspectRatioProps>(
  (props, ref) => {
    const { UNSAFE_className, UNSAFE_style, ...rest } = props;

    return (
      <Root
        className={UNSAFE_className}
        style={UNSAFE_style}
        ref={ref}
        {...rest}
      />
    );
  }
);

AspectRatio.displayName = '@backyard-ui/core/AspectRatio';

export default AspectRatio;
