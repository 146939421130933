import { createContext } from '@backyard-ui/utils';
import type { TabsProps } from './Tabs';

export interface TabsContext extends Pick<TabsProps, 'color'> {}

const { Provider: TabsProvider, useContext: useTabsContext } =
  createContext<TabsContext>({
    name: 'TabsContext',
  });

export { TabsProvider, useTabsContext };
