import React from 'react';
import type { HTMLAttributes, ElementRef, Ref } from 'react';

import type { StyleProps } from '@backyard-ui/styles';

import styles from './Tag.styles';

export interface TagLabelProps
  extends StyleProps<HTMLAttributes<HTMLSpanElement>> {
  /**
   * The ref to the HTML DOM element
   */
  ref?: Ref<HTMLSpanElement>;

  /**
   * The children elements
   */
  children: string;
}

const TagLabel = React.forwardRef<ElementRef<'span'>, TagLabelProps>(
  (props, ref) => {
    const { UNSAFE_className, UNSAFE_style, ...rest } = props;

    return (
      <span
        className={styles().label({
          className: UNSAFE_className,
        })}
        style={UNSAFE_style}
        ref={ref}
        {...rest}
      />
    );
  }
);

TagLabel.displayName = '@backyard-ui/core/TagLabel';

export default TagLabel;
