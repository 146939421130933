import React from 'react';
import type { ElementRef } from 'react';

import type { StyleProps } from '@backyard-ui/styles';

import { Root } from '@radix-ui/react-collapsible';
import type { CollapsibleProps as RootProps } from '@radix-ui/react-collapsible';

import styles from './Collapsible.styles';

export interface CollapsibleProps
  extends StyleProps<Omit<RootProps, 'asChild'>> {
  /**
   * If `true` the trigger will be disabled
   */
  isDisabled?: boolean;

  /**
   * If `true` the will be rendered opened
   */
  isOpen?: boolean;
}

const Collapsible = React.forwardRef<ElementRef<typeof Root>, CollapsibleProps>(
  (props, ref) => {
    const { UNSAFE_className, UNSAFE_style, isDisabled, isOpen, ...rest } =
      props;

    return (
      <Root
        className={styles().base({ className: UNSAFE_className })}
        style={UNSAFE_style}
        disabled={isDisabled}
        open={isOpen}
        ref={ref}
        {...rest}
      />
    );
  }
);

Collapsible.displayName = '@backyard-ui/core/Collapsible';

export default Collapsible;
