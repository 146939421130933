import React from 'react';
import type { InputHTMLAttributes, ElementRef } from 'react';

import { Icon } from './Icon';

import { useCheckboxGroupContext } from './Checkbox.context';
import { useCheckbox } from './use-checkbox';
import type { UseCheckboxProps } from './use-checkbox';

import styles from './Checkbox.styles';

export interface CheckboxProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'size' | 'value'>,
    UseCheckboxProps {
  /**
   * The Checkbox size
   *
   * @default sm
   */
  size?: 'xs' | 'sm';
}

const Checkbox = React.forwardRef<ElementRef<'input'>, CheckboxProps>(
  (props, ref) => {
    const { size = 'sm', className, children, ...rest } = props;

    const group = useCheckboxGroupContext();
    const { getRootProps, getLabelProps, getInputProps, getCheckboxProps } =
      useCheckbox({
        isChecked: group?.value.includes(rest.value as string),
        onChange: group?.onChange,
        ...rest,
      });

    return (
      <div className={styles().base({ className })} {...getRootProps()}>
        <input
          type="checkbox"
          className={styles().input()}
          {...getInputProps(rest, ref)}
        />
        <label
          className={styles({ size: group?.size || size }).label()}
          {...getLabelProps()}
        >
          <Icon {...getCheckboxProps()} />
          {children}
        </label>
      </div>
    );
  }
);

Checkbox.displayName = 'Checkbox';

export default Checkbox;
