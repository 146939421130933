import React from 'react';
import type { ElementRef } from 'react';

import type { StyleProps } from '@backyard-ui/styles';

import { Label } from '@radix-ui/react-select';
import type { SelectLabelProps as LabelProps } from '@radix-ui/react-select';

import { useSelectContext } from './Select.context';

import styles from './Select.styles';

export interface SelectLabelProps
  extends StyleProps<Omit<LabelProps, 'asChild'>> {}

const SelectLabel = React.forwardRef<
  ElementRef<typeof Label>,
  SelectLabelProps
>((props, ref) => {
  const { UNSAFE_className, UNSAFE_style, ...rest } = props;
  const { size } = useSelectContext();

  return (
    <Label
      className={styles({ size }).label({
        className: UNSAFE_className,
      })}
      style={UNSAFE_style}
      ref={ref}
      {...rest}
    />
  );
});

SelectLabel.displayName = '@backyard-ui/core/SelectLabel';

export default SelectLabel;
