import { tv } from 'tailwind-variants';

const styles = tv({
  base: `
    relative

    box-content
    inline-flex

    cursor-pointer
    appearance-none

    justify-start

    rounded-full

    bg-neutral-200

    p-0.5

    outline-none
    transition
    duration-150

    focus:border-primary-600
    focus:ring-2
    focus:ring-primary-300

    disabled:cursor-not-allowed
    disabled:opacity-40

    data-[invalid]:border-critical-600
    data-[invalid]:data-[state=checked]:bg-critical-600
    data-[state=checked]:bg-primary-600
    data-[invalid]:focus:ring-critical-300
  `,
  slots: {
    thumb: `
      rounded-[inherit]
      bg-white

      transition-transform
      duration-100
      ease-in

      data-[state=checked]:translate-x-[var(--switch-thumb-x)]
    `,
  },
  variants: {
    size: {
      xs: {
        base: `
          w-6
          h-3
        `,
        thumb: `
          w-[calc(theme("spacing.6")-theme("spacing.3"))]
          h-3

          data-[state=checked]:translate-x-[calc(theme("spacing.6")-theme("spacing.3"))]
        `,
      },
      sm: {
        base: `
          w-8
          h-4
        `,
        thumb: `
          w-[calc(theme("spacing.8")-theme("spacing.4"))]
          h-4

          data-[state=checked]:translate-x-[calc(theme("spacing.8")-theme("spacing.4"))]
        `,
      },
      md: {
        base: `
          w-12
          h-6
        `,
        thumb: `
          w-[calc(theme("spacing.12")-theme("spacing.6"))]
          h-6

          data-[state=checked]:translate-x-[calc(theme("spacing.12")-theme("spacing.6"))]
        `,
      },
    },
  },
});

export default styles;
