import { createContext } from '@backyard-ui/utils';

import type { SelectProps } from './Select';
import { FormControlOptions } from '../FormControl';

export interface SelectContext
  extends Omit<FormControlOptions, 'isDisabled'>,
    Pick<SelectProps, 'size'> {}

/**
 * The radix does not pass the `props` to trigger like:
 * aria-*, readOnly etc.
 * This context, only get FormControlOptions and pass it to trigger.
 */
const { Provider: SelectProvider, useContext: useSelectContext } =
  createContext<SelectContext>({
    name: '@backyard-ui/core/SelectContext',
  });

export { SelectProvider, useSelectContext };
