import React from 'react';
import type { ElementRef } from 'react';

import type { StyleProps } from '@backyard-ui/styles';

import { Root } from '@radix-ui/react-accordion';
import type {
  AccordionSingleProps,
  AccordionMultipleProps,
} from '@radix-ui/react-accordion';

import styles from './Accordion.styles';

export type AccordionProps =
  | StyleProps<Omit<AccordionSingleProps, 'asChild'>>
  | StyleProps<Omit<AccordionMultipleProps, 'asChild'>>;

const Accordion = React.forwardRef<ElementRef<typeof Root>, AccordionProps>(
  (props, ref) => {
    const { UNSAFE_className, UNSAFE_style, ...rest } = props;

    return (
      <Root
        className={styles().base({
          className: UNSAFE_className,
        })}
        style={UNSAFE_style}
        ref={ref}
        {...rest}
      />
    );
  }
);

Accordion.displayName = '@backyard-ui/core/Accordion';

export default Accordion;
