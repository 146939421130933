import React from 'react';
import type { HTMLAttributes, ElementRef, Ref, ReactNode } from 'react';

import type { StyleProps } from '@backyard-ui/styles';

import { Root } from '@radix-ui/react-select';
import type { SelectProps as RootProps } from '@radix-ui/react-select';
import type { FormControlOptions } from '../FormControl';

import { SelectProvider } from './Select.context';

import styles from './Select.styles';

export interface SelectProps
  extends StyleProps<
      Omit<HTMLAttributes<HTMLDivElement>, 'dir' | 'defaultValue'>
    >,
    Omit<RootProps, 'open'>,
    FormControlOptions {
  /**
   * The input size.
   *
   * @default sm
   */
  size?: 'xs' | 'sm' | 'md';

  /**
   * If `true` the Dialog will be initially opened
   *
   * @default false
   */
  isOpen?: boolean;

  /**
   * The ref to the HTML DOM element
   */
  ref?: Ref<HTMLDivElement>;

  /**
   * The children elements
   */
  children: ReactNode;
}

const Select = React.forwardRef<ElementRef<'div'>, SelectProps>(
  (props, ref) => {
    const {
      size = 'sm',
      isOpen,
      isDisabled,
      isInvalid,
      isReadOnly,
      isRequired,
      UNSAFE_className,
      UNSAFE_style,
      ...rest
    } = props;

    return (
      <SelectProvider
        value={{
          size,
          isInvalid,
          isReadOnly,
          isRequired,
        }}
      >
        <div
          className={styles().base({
            className: UNSAFE_className,
          })}
          style={UNSAFE_style}
          ref={ref}
        >
          <Root disabled={isDisabled || isReadOnly} open={isOpen} {...rest} />
        </div>
      </SelectProvider>
    );
  }
);

Select.displayName = '@backyard-ui/core/Select';

export default Select;
