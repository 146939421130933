import React from 'react';
import type { HTMLAttributes, ElementRef, Ref, ReactNode } from 'react';

import type { StyleProps } from '@backyard-ui/styles';

import { useCardContext } from './Card.context';

import styles from './Card.styles';

export interface CardHeaderProps
  extends StyleProps<HTMLAttributes<HTMLDivElement>> {
  /**
   * The ref to the HTML DOM element
   */
  ref?: Ref<HTMLDivElement>;

  /**
   * The children elements
   */
  children: ReactNode;
}

const Header = React.forwardRef<ElementRef<'div'>, CardHeaderProps>(
  (props, ref) => {
    const { UNSAFE_className, UNSAFE_style, ...rest } = props;
    const { size } = useCardContext();

    return (
      <div
        className={styles({ size }).header({
          className: UNSAFE_className,
        })}
        style={UNSAFE_style}
        ref={ref}
        {...rest}
      />
    );
  }
);

Header.displayName = '@backyard-ui/core/CardHeader';

export default Header;
