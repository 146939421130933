import React from 'react';
import type { ElementRef } from 'react';

import type { StyleProps } from '@backyard-ui/styles';

import { Header } from '@radix-ui/react-accordion';
import type { AccordionHeaderProps as HeaderProps } from '@radix-ui/react-accordion';

import styles from './Accordion.styles';

export interface AccordionHeaderProps
  extends StyleProps<Omit<HeaderProps, 'asChild'>> {}

const AccordionHeader = React.forwardRef<
  ElementRef<typeof Header>,
  AccordionHeaderProps
>((props, ref) => {
  const { UNSAFE_className, UNSAFE_style, ...rest } = props;

  return (
    <Header
      className={styles().header({
        className: UNSAFE_className,
      })}
      style={UNSAFE_style}
      ref={ref}
      {...rest}
    />
  );
});

AccordionHeader.displayName = '@backyard-ui/core/AccordionHeader';

export default AccordionHeader;
