import { createContext } from '@backyard-ui/utils';
import type { RadioGroupProps } from './RadioGroup';

export interface RadioGroupContext extends Pick<RadioGroupProps, 'size'> {}

const { Provider: RadioGroupProvider, useContext: useRadioGroupContext } =
  createContext<RadioGroupContext>({
    name: 'RadioGroupContext',
  });

export { RadioGroupProvider, useRadioGroupContext };
