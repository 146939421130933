export { default as Root } from './Tabs';
export type { TabsProps } from './Tabs';

export { default as List } from './List';
export type { TabsListProps } from './List';

export { default as Trigger } from './Trigger';
export type { TabsTriggerProps } from './Trigger';

export { default as Content } from './Content';
export type { TabsContentProps } from './Content';
