import { createContext } from '@backyard-ui/utils';
import type { ChangeEvent } from 'react';

import type { CheckboxProps } from './Checkbox';

export interface CheckboxGroupContext extends Pick<CheckboxProps, 'size'> {
  /**
   *
   */
  value: string[];

  /**
   *
   * @param event
   */
  onChange(event: ChangeEvent<HTMLInputElement>): void;
}

const { Provider: CheckboxGroupProvider, useContext: useCheckboxGroupContext } =
  createContext<CheckboxGroupContext>({
    name: '@backyard-ui/core/CheckboxGroupContext',
    strict: false,
  });

export { CheckboxGroupProvider, useCheckboxGroupContext };
