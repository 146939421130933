import { tv } from 'tailwind-variants';

const styles = tv({
  slots: {
    base: `
      group
      relative
    `,
    input: 'sr-only',
    label: `
      relative
      inline-flex

      cursor-pointer
      select-none

      text-neutral-900

      before:absolute
      before:left-0
      before:top-2/4

      before:block

      before:h-4
      before:w-4

      before:-translate-y-2/4

      before:rounded-[4px]
      before:border
      before:border-gray-300
      before:bg-white

      before:transition
      before:duration-100
      before:ease-in

      before:content-['']

      [&_svg]:absolute
      [&_svg]:left-0
      [&_svg]:top-2/4
      [&_svg]:-translate-y-2/4
      [&_svg]:text-green-600

      group-hover:before:border-gray-400
      group-focus:border-primary-600
      group-focus:shadow-[0_0_0_2px]
      group-focus:shadow-primary-300

      group-data-[disabled]:before:pointer-events-none
      group-data-[disabled]:before:bg-neutral-100
      group-data-[disabled]:before:text-neutral-300

      group-data-[invalid]:before:border-critical-600
      group-data-[invalid]:before:shadow-critical-300

      group-data-[readOnly]:before:pointer-events-none
      group-data-[readOnly]:before:bg-neutral-100
      group-data-[readOnly]:before:text-neutral-300
    `,
  },
  variants: {
    size: {
      xs: {
        label: `
          pl-4

          text-sm

          before:h-3
          before:w-3

          [&_svg]:h-3
          [&_svg]:w-3
          [&_svg]:p-0.5
        `,
      },

      sm: {
        label: `
          pl-7

          text-lg

          before:h-5
          before:w-5

          [&_svg]:h-5
          [&_svg]:w-5
          [&_svg]:p-1
       `,
      },
    },
  },
});

export default styles;
